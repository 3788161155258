.main {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
}

.picture404 {
	min-width: 24.6rem;
	display: block;
}

.text1 {
	font-size: 3rem;
	color: var(--violet-dark-web-space);
	padding-bottom: 0.8rem;
	font-family: "Akzidenz-Grotesk Pro Med Ext";
}

.text2 {
	font-size: 2rem;
	color: var(--violet-light-web-space);
	padding-bottom: 2.4rem;
	font-family: "Akzidenz-Grotesk Pro Ext";
	line-height: 2.4rem;
}

.text3 {
	font-family: "Akzidenz-Grotesk Pro Light";
	font-size: 1.6rem;
	padding-bottom: 5.6rem;
	line-height: 1.6rem;
}

.link {
	border-radius: 1rem;
	background: var(--violet-dark-web-space);
	box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);
	color: white;
	font-size: 1.6rem;
	padding: 1.2rem 1.6rem;
	align-self: center;
	cursor: pointer;
	text-decoration: none;
}

.container {
	display: flex;
	flex-direction: column;
}
