.main {
  flex-direction: column;
  align-items: center;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  display: flex;
}

.picture404 {
  min-width: 24.6rem;
  display: block;
}

.text1 {
  color: var(--violet-dark-web-space);
  padding-bottom: .8rem;
  font-family: Akzidenz-Grotesk Pro Med Ext;
  font-size: 3rem;
}

.text2 {
  color: var(--violet-light-web-space);
  padding-bottom: 2.4rem;
  font-family: Akzidenz-Grotesk Pro Ext;
  font-size: 2rem;
  line-height: 2.4rem;
}

.text3 {
  padding-bottom: 5.6rem;
  font-family: Akzidenz-Grotesk Pro Light;
  font-size: 1.6rem;
  line-height: 1.6rem;
}

.link {
  background: var(--violet-dark-web-space);
  color: #fff;
  cursor: pointer;
  border-radius: 1rem;
  align-self: center;
  padding: 1.2rem 1.6rem;
  font-size: 1.6rem;
  text-decoration: none;
  box-shadow: 2px 2px 6px #0003;
}

.container {
  flex-direction: column;
  display: flex;
}
/*# sourceMappingURL=no-encontrado.4b6ac79b.css.map */
